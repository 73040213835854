import { Container } from "typescript-ioc";
import { ReadyHelper } from "Events/Scripts/ReadyHelper";
import { ConsultantsApi } from "./ConsultantsApi";

declare global {
    interface IOri {
        /**
         * Global API Gateway Module
         */
        consultantsApi?: ConsultantsApi;
    }
}

const consultantsApi = Container.get(ConsultantsApi);

// publish for access from legacy JS code
ori.consultantsApi = consultantsApi;
ori.modules.push("consultantsApi");

// init from markup data on DOM ready
Container.get(ReadyHelper).bindDomReady(() => consultantsApi.init());
